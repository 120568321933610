@import "../../style/variables";

.img-avatar {
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  margin: 0;
  overflow: hidden;
  display: inline-block;

  .user-photo {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.icon-only {
    border: 1px dotted rgba($dark, 0.75);
    font-size: 0.5rem;
    text-align: center;
  }

  &.big {
    width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;

    &.icon-only {
      font-size: 1rem;
    }
  }

  &.dark {
    &.icon-only {
      border-color: rgba($white, 0.325);
    }
  }
}

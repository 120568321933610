$default-dimension: 1rem;

.type-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        font-size: $default-dimension;
        height: $default-dimension;
        line-height: $default-dimension;
        width: $default-dimension;
    }

    svg {
        height: $default-dimension;
    }

    .tooltip-anchor {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.btn .type-icon {
    opacity: 0.75;
}
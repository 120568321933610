$datepicker__selected-color: #63c2de;

@import "~react-datepicker/src/stylesheets/datepicker";

.widget-controls {
  font-size: 0.75rem;

  .form-control-sm,
  .form-control,
  .btn {
    margin: 0 0.1rem;
    font-size: 0.75rem;
    padding: 0.1rem 0.25rem;
    height: calc(1.25rem + 0.2rem + 2px);
    min-width: calc(1.25rem + 0.2rem + 2px);
    line-height: 1.25;
  }

  .react-datepicker__close-icon:after {
    background-color: #ffffff;
    color: $datepicker__selected-color;
    font-size: 18px;
    height: 20px;
    width: 20px;
    text-align: center;
    padding: 1px;
  }

  .react-datepicker__close-icon:hover {
    &:after {
      background-color: $datepicker__selected-color;
      color: #ffffff;
      font-size: 16px;
      padding: 1px;

    }
  }
}

@import "../../style/variables";

.page-header.page-box {
  margin-top: 1rem;
  margin-bottom: 0rem;
  background-color: transparent;
  box-shadow: none;
  padding-top: 0.5rem;
  padding-bottom: 0;
  position: relative;
  left: 0;
  border-color: transparent;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: top;
  flex-wrap: wrap;
  flex-shrink: 0;
  padding-left: 0.25rem;

  .page-headline {
    margin-bottom: 2.375rem;
    display: flex;
    align-items: center;

    .editable, .text {
      padding-left: 0.5rem;
      display: flex;
      align-items: center;
    }

    .editable {
      border-radius: 0.5rem;
      cursor: pointer;
      i {
        opacity: 0;
        transition: opacity 0.25s;
        font-size: 50%;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
      &:hover {
        box-shadow: 0 0 3px 0 rgba($color-di, 0.5);
        i {
          opacity: 1;
        }
      }
    }

    .input-group {
      min-width: 12rem;
    }
  }

  &.with-breadcrumb {
    padding-bottom: 0.5rem;

    .page-headline {
      margin-bottom: 0;
    }

    .line {
      margin-top: 1.25rem;
    }
  }

  .page-header-left {
    margin-right: 2rem;
  }

  .page-breadcrumb * {
    white-space:  nowrap !important;
  }

  .page-header-right {
    display: flex;
    flex-wrap: nowrap;
    margin-right: 1rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;

    * {
      white-space: nowrap !important
    }
  }

  .page-header-item {
    + .page-header-item {
      margin-left: 1rem;
    }
  }

  .line {
    content: '';
    border-top: 0.125rem dotted $gray-200;
    position: absolute;
    left: 0rem;
    width: 100vw;
    margin-top: -1.125rem;
    height: 1px;
  }
}

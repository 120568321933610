@import "../../style/variables";

.page-breadcrumb {
  padding-top: 0.75rem;
  padding-bottom: 0.625rem;
  padding-left: 0.5rem;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    z-index: 1;
    position: relative;

    li {
      font-size: 75%;
      background-color: $body-bg;

      &:first-child {
        padding-left: 0.25rem;
        margin-left: -0.25rem;
      }

      &:last-child {
        padding-right: 0.25rem;
      }

      .last {
        font-weight: $font-weight-bold;
      }

      > *, &:before {
        opacity: 0.75;
      }

      a {
        color: $body-color;
        text-decoration: none;
        transition: all 0.25s;

        &:hover {
          text-decoration: underline;
          color: $primary;
        }
      }
    }
  }
}

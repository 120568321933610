@import '../../style/variables';

.info-widget-card-body-content {
  display: flex;
  justify-content: center;
  align-content: center;
}

.info-widget-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .value {
    color: $primary;
    font-size: 4em;
  }
}

@import "../../style/variables";

.page-headline {
  margin: 0;

  small {
    text-transform: none;
    font-size: 50%;
    letter-spacing: 0;
    margin-left: 0.5rem;
    opacity: 0.75;
  }
  
  &.type-h-1 {
    text-transform: uppercase;
    color: $color-di;
    position: relative;
  }

  &.type-h-2 {
    text-transform: uppercase
  }

  &.type-h-3 {
    text-transform: uppercase;
  }
}
@import './variables';

.modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        background: $secondary-bg;

        text-transform: uppercase;

        .close {
          font-size: 30px;

          span {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            top: -1px;
          }
        }
      }

      .modal-body {
        p {
          &:only-child {
            margin: 1rem 0;
          }
        }
      }
    }

    @media (min-width: 992px) {
      &.modal-lg {
        max-width: 740px;
      }
    }
  }
}
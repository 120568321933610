@import "../../style/variables";

.grid-wrapper {
  overflow: auto;
}

.layout {
  margin: 0;

  .grid-item {

    &.fullscreen {

      .react-resizable-handle {
        display: none;
      }
    }

    &.react-resizable {
      .dashboard-card .widget-actions {
        opacity: 0.5;
        transition: opacity 0.25s;
      }

      .react-resizable-handle {
        opacity: 0.33;
        transition: opacity 0.25s;
      }

      &:hover, .dashboard-card.menu-active {
        .card-header {
          .widget-actions {
            opacity: 1;
            .btn i {
              font-size: 100%;
            }
          }
        }
      }

      &:hover, .dashboard-card.menu-active + {
        .react-resizable-handle {
          opacity: 1;
        }
      }
    }

    .dashboard-card {
      border: 0;

      background-color: transparent;
      border-color: transparent;
      box-shadow: none;

      .card-header {
        background: transparent;
        padding: 0.25rem 0.5rem 0 1rem;
        border-color: transparent;
        background-color: transparent;
        font-size: $sizeOfFontInCardHeader;
        font-weight: $font-weight-bold;

        .widget-name {
          font-size: 90%;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: rgba($dark, 0.8);
        }

      }

      .card-body,
      label {
        font-size: $sizeOfFontInCardBody;

        .table td {
          font-size: 13px;
          font-weight: $font-weight-normal;
          padding: 7px 0.3rem;
        }

        .table th {
          font-size: 14px;
          font-weight: $font-weight-bold;
          padding: 7px 0.3rem;
        }
      }

      .card-body {
        display: flex;
        padding: 0;

        .card-body-inner {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          position: relative;

          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0.5rem;
            left: 0.5rem;
            right: 0.5rem;
            bottom: 0.5rem;
            background-color: #fff;
            box-shadow: 0 0 0.5rem 0 #ddd;
            border-radius: 0.5rem;
            z-index: 0;
          }

          .card-body-content {
            flex: 1 1 100%;
            padding: 1rem 1rem 1rem 1rem;
            overflow: auto;
            position: relative;
            // z-index: 1;
          }

          .widget-controls {
            padding: 1rem 0 0.5rem 0;
            margin: 0 1rem 0.5rem 1rem;
            border-bottom: 1px dotted $gray-200;
            position: relative;
            // z-index: 2;

            + .card-body-content {
              padding-top: 0rem;
            }
          }
        }

        .table,
        .table thead th,
        .table tbody td,
        .form-control {
          border-color: $gray-200;
        }
      }
    }
  }
}

@import "../../../style/variables";

.widget-actions {
  display: flex;
  align-items: center;

  &.fullscreen {
    opacity: 1 !important;
  }

  .btn {
    border-color: transparent;

    i {
      transform: scale(0.875);
    }
  }
  
  .show > .btn-primary.dropdown-toggle {
    background-color: $primary;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:after,
    &:before,
    i:before,
    i:after {
      color: white;
    }
  }

  // center down arrow in dropdown
  .dropdown-toggle:after {
    margin-left: 0;
  }

  .custom-right-arrow {
    margin-right: 0;
    margin-left: 1rem;
  }

  .dropdown-menu {
    .dropdown-item {
      padding: 0.25rem 0.75rem;
      margin-right: 1rem;
      border-bottom-color: #e6e6e6;
      transition: all 0.15s;
      > [class^="icon-"] {
        display: inline-block;
      }
      &:hover {
        background: $primary;
        color: #fff;

        i {
          color: #fff;
        }
      }
      &.dropdown-sub-item {
        background-color: $gray-100 ;
        padding-left: 2.25rem;

        &:hover {
          background-color: $primary;
        }
      }
    }

    button {
      display: flex;
      align-items: center;
      min-height: 2.5rem;
      i {
        width: 1rem;
        margin-right: 0.5rem;
        text-align: center;
        float: left; // @todo - fix
      }
    }

    .dropdown-menu-level2 {
      margin-left: 100%;
      margin-top: -3.5rem; // @todo - fix globally
    }
  }
}

@import './variables';

@mixin minimalist-scrollbar {
  &, & * {
    &::-webkit-scrollbar {
      width: 0.4rem;
    }
    & {
      scrollbar-width: thin;
      scrollbar-color: $scrollbar-thumb-color $scrollbar-bg-color;
    }
    &::-webkit-scrollbar-track {
      background: $scrollbar-bg-color;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-thumb-color;
      border-radius: 0.75rem;
      border: 0.25rem solid $scrollbar-thumb-border-color;
    }
  }
}

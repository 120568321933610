@import "../../style/variables";

.dashboard-card {
  border-color: transparent;

  &.hidden {
    .card-body {
      overflow: hidden;
    }

    .card-body .table-responsive {
      overflow: auto;
    }
  }

  &.card {
    margin-bottom: 0;
  }

  .chart-wrapper {
    height: 100%;
    position: relative;
  }

  &.fullHeight {
    .card-body {
      max-height: inherit;
      overflow: inherit;

      .table-responsive {
        // overflow: inherit;
      }
    }
  }

  .card-header {
    .widget-name {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .widget-name-tag {
      border-radius: $border-radius;
      font-size: 10px;
      padding: 0.125rem 0.25rem;
      text-transform: uppercase;
      margin-right: 0.5rem;

      &.widget-name-tag-new {
        border: 1px solid $primary;
        background: $primary-bg;
      }
    }
  }
}


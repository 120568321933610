@import "./variables";

.Toastify__toast-container.custom-toast-container {
  top: 0.5rem !important;
  padding: 0;

  .Toastify__toast {
    border-radius: 0.5rem;
    box-shadow: 0 0 3px 1px #444;
  }

  .Toastify__toast--success {
    background: $success;
  }

  .Toastify__toast--error {
    background: $danger;
  }

  .Toastify__toast-body {
    padding: 0 0.75rem;
  }
}

@import "../../style/variables";

.page-content.page-box {
  margin-bottom: 0.5rem;
  // flex-grow: 1;
  padding-top: 0.25rem;
  border-radius: 0;

  +.page-content.page-box {
    border-top: 0.125rem dotted $gray-200;
    padding-top: 1.5rem;
  }

  &.no-padding {
    padding: 0;
    margin-bottom: 0;
  }
}
@import "../../style/minimalist-scrollbar";

.entity-workspace-relation {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  max-height: calc(100vh - 10rem);
  overflow: auto;
  @include minimalist-scrollbar;

  .workspace-name {
    display: flex;
    align-items: center;

    i {
      margin-right: .5rem;
      width: 1rem;
      height: 1rem;
      line-height: 1rem;
      text-align: center;
    }

    &.with-subtitle {
      .subtitle {
        font-size: 87.5%;
        opacity: 0.75;
        margin-left: 0.5rem;

        &:before {
            content: '·';
            margin-right: .5rem;
        }
      }
    }
  }

  .my-preset {
    margin-left: 0.25rem;
  }
}

.entity-workspace-relation-popover-target {
  display: flex;
  align-items: center;

  .badge {
    min-width: 1rem;
  }
}

div, span {
  &.entity-workspace-relation-popover-target {
    .badge {
      margin-left: 0.25rem;
    }
  }
}

span {
  &.entity-workspace-relation-popover-target {
    display: inline-flex;
  }
}

.entity-workspace-relation-empty {
  opacity: 0.75;
  font-style: italic;
  text-transform: lowercase;
  font-weight: $font-weight-normal;
}

.error-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #fff;
    overflow: auto;
  }

  .error-holder {
    background: #fff;
    border: 1px solid #d7e0f1;
    box-shadow: 0 0 12px rgba(139, 139, 139, 0.13);
    border-radius: 0.5rem;
    padding: 1.5rem;
    text-align: center;
    min-width: 15rem;

    .error-text {
      margin: 0.5rem 0 2rem 0;

      &:last-child {
        margin-bottom: 0.5rem;
      }
    }
  }
}
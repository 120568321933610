@import "./variables";

.common-table {
  border: 1px solid #e4e7ea;
  border-radius: 5px;
  overflow: hidden;

  .common-table-header {
    background-color: $secondary-bg;
    display: flex;

    .common-table-col {
      padding: 4px 10px;
      flex-grow: 0;

      &.actions {
        flex-grow: 0;
        flex-shrink: 0;
        align-self: center;

        .col-text {
          text-align: right;

          &.invisible {
            visibility: hidden;
          }
        }
      }

      .col-text {
        font-weight: $font-weight-bold;
        margin: 5px 0;
      }
    }
  }

  .common-table-content {
    .common-table-col {
      padding: 4px 10px;
      display: flex;
      align-items: center;
      position: relative;

      &.actions-col {
        flex-grow: 0;
        flex-shrink: 0;
        align-self: center;
        justify-content: flex-end;
        flex-wrap: wrap;
      }
    }

    .common-table-line {
      display: flex;

      &:nth-child(2n) {
        background: $secondary-bg;
      }
    }

    .common-table-entity-name {
      display: flex;
      flex-direction: column;

      &.with-subtitle {
        font-size: 87.5%;

        .subtitle {
          font-size: 75%;
          opacity: 0.75;
        }
      }

      i.my-preset {
        display: none;
      }

      &.my-preset {
        padding-right: 1rem;

        i.my-preset {
          display: block;
          position: absolute;
          right: 0.5rem;
          top: 50%;
          transform: translateY(-50%) scale(0.875);
          opacity: 0.5;
        }
      }
    }
  }

  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
}

@import "./variables";

[class*="icon-status-update"] {
  margin-right: 0.5rem;
  margin-left: -0.5rem;
  > i {
    display: inline-block;
    margin: 0;
    transform-origin: center;
  }
}

.btn, .dropdown-item  {
  > [class*="icon-status-update"] {
    margin: 0;
  }
}

.icon-status-update-queue,
.icon-status-update-running {
  > i {
    animation: rotating 2s linear infinite reverse;
  }
}

.icon-status-update-error {
  color: $danger;

  > i {
    color: $danger;
  }
}

.icon-status-update-done {
  color: $success;

  > i {
    color: $success;
  }
}

.icon-status-update-canceled {
  color: $orange;

  > i {
    color: $orange;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

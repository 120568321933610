.widget-switch-modal {
  .alert {
    margin-top: 1rem;

    h5 {
      text-transform: uppercase;
      display: flex;
      i {
        margin-right: 0.375rem;
      }
    }

    +.alert {
      margin-top: 1rem;
    }
  }
}

.dashboard-select-option {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .my-preset {
    margin-left: 0.5rem;
  }

  .subtitle {
    margin-left: 0.5rem;
    font-size: 87.5%;
    opacity: 0.875;

    &:before {
      content: '·';
      margin-right: 0.5rem;
    }
  }

  .unnamed {
    opacity: 0.5;
    font-style: italic;
  }

  .entity-workspace-relation-popover-target {
    margin-left: 1rem;
  }
}



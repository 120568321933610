@import "../../style/minimalist-scrollbar";

.table-responsive {
  @include minimalist-scrollbar;

  height: 100%;
  max-height: 100%;
  font-size: 0.75em;

  table {
    thead {
      tr {
        th {
          border-top: 0;
          white-space: nowrap;
        }
      }
    }
  }
}

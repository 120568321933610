// Colors

// our theme colors - do not use them directly in scss

// SNAZZY THEME
// $theme-foregroundColor: #eff0eb; 
// $theme-backgroundColor: #282a36; 
// $theme-red: #ff5c57;
// $theme-orange: #fdb96f; // from red and yellow 
// $theme-green: #5af78e; 
// $theme-yellow: #f3f99d; 
// $theme-blue: #57c7ff; 
// $theme-magenta: #ff6ac1; 
// $theme-teal: #00dfca; // from green and cyan
// $theme-cyan: #9aedfe;
// $theme-purple: #9b83bc; // from bg and magenta
// $theme-indigo: #5a5678; // from bg and magenta


// TOMORROW NIGHT
$theme-foregroundColor: #c3c7c9; 
$theme-backgroundColor: #131e23; 
$theme-red: #cc6666;
$theme-orange: #de935f; 
$theme-green: #0ea177; 
$theme-yellow: #f0c674; 
$theme-blue: #81a2be; 
$theme-magenta: #ff7a9b; // from red
$theme-teal: #5bbaac; // from green and blue
$theme-cyan: #38e8fb; // from blue
$theme-purple: #db455e;
$theme-indigo: #33687c; // from bg

// override bootstrap colors and settings

$white:    #fff;

// FOR SNAZZY
// $gray-900:#162b3d;
// $gray-800:#304051;
// $gray-700:#495766;
// $gray-600:#646f7c;
// $gray-500:#7f8893;
// $gray-400:#9ba2aa;
// $gray-300:#b7bcc2;
// $gray-200:#d5d7da;
// $gray-100:#f3f3f3;

// FOR TOMORROW NIGHT
$gray-900:#131e23;
$gray-800:#1a3540;
$gray-700:#354b55;
$gray-600:#50626b;
$gray-500:#6b7a81;
$gray-400:#889398;
$gray-300:#a5adb0;
$gray-200:#c3c7c9;
$gray-100:#ebebeb;

$black:    #000;

$blue:    $theme-blue;
$indigo:  $theme-indigo;
$purple:  $theme-purple;
$pink:    $theme-magenta;
$red:     $theme-red;
$orange:  $theme-orange;
$yellow:  $theme-yellow;
$green:   $theme-green;
$teal:    $theme-teal;
$cyan:    $theme-cyan;
$color-di:  $purple;

$primary:       $indigo;
$secondary:     $gray-200;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "di":         $color-di,
);

$enable-rounded: true;

$border-radius:               .5rem;
$border-radius-sm:            .33rem;
$border-radius-lg:            .75rem;

$body-bg: $white;

$input-border-color: $gray-200;

$border-color: $gray-200;

$font-family-sans-serif: 'Open Sans', sans-serif;

$font-weight-normal: 400;
$font-weight-bold: 700;

$headings-font-weight: 600;
$headings-font-family: 'Open Sans', sans-serif;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$secondary-bg: $gray-100;

$sizeOfFontInCardHeader: 18px;
$sizeOfFontInCardBody: 0.75rem;

$color-floating-panels-bg: $dark;
$color-floating-panels-selection-bg: desaturate(lighten($dark, 8%), 20%);

$primary-bg: rgba($primary, 0.2);
$primary-bg-dark: rgba($primary, 0.4);

$sidebar-open-width: 16rem;
$sidebar-closed-width: 4rem;

$border-radius-floating-panels: 1rem;
$box-shadow-floating-panels-color: rgba($gray-400, 0.5);
$box-shadow-floating-panels: 0 0px 4px 1px $box-shadow-floating-panels-color;

$scrollbar-main-color: desaturate(lighten($dark, 30%), 30%);
$scrollbar-thumb-color: transparentize($scrollbar-main-color, 0.33);
$scrollbar-thumb-border-color: transparent;
$scrollbar-bg-color: transparentize($scrollbar-main-color, 0.66);

@mixin floating-panel-selection-text {
  color: desaturate(lighten($color-di, 3%), 3%);
  text-shadow: 0 0 1px rgba($color-di, 0.8);
}

@mixin heading-letter-spacing($font-size) {
  @if $font-size {
    letter-spacing: max(0.05 * $font-size, 0.08rem);
  }
}

@mixin heading-font($font-size) {
  font-family: $headings-font-family;
  // font-style: $headings-font-style;
  font-weight: $headings-font-weight;
  font-size: $font-size;
  @include heading-letter-spacing($font-size)
}

h1 {
  @include heading-letter-spacing($h1-font-size);
}

h2 {
  @include heading-letter-spacing($h2-font-size);
}

h3 {
  @include heading-letter-spacing($h3-font-size);
}

h4 {
  @include heading-letter-spacing($h4-font-size);
}

h5 {
  @include heading-letter-spacing($h5-font-size);
}

h6 {
  @include heading-letter-spacing($h6-font-size);
}
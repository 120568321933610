@import "../../style/variables";

.page-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 0.5rem 0 0.5rem;
  min-height: 100%;
  justify-content: flex-start;

  .response-content{
    overflow: auto;
    margin-top: 10px;
    height: calc(100% - 500px);
    width: 100%;
    flex: 1 0 auto;

    margin-bottom: 0;
  }
}

.page-box {
  background: $body-bg;
  // border: 1px solid #d7e0f1;
  // box-shadow: 0 0 16px 0 rgba(139, 139, 139, 0.13);
  border-radius: 0.5rem;
  padding: 0.75rem;
  // margin-left: 0.25rem;

  &.fullscreen-content {
    padding-bottom: 0;
  }
}

@import "../../../style/variables";

.m-columns {
  margin: -8px -10px 0;

  .m-card-link {
    margin: 0;
    padding: 0 10px 10px;
    height: 100%;
    z-index: 1;

    &:hover {
      text-decoration: none;
    }

    @media only screen and (max-width: 767px) {
      & + .m-card-link {
        margin-top: 10px;
      }
    }
  }

  .m-card {
    background-color: aliceblue;
    border-color: darkgray;
    margin: 8px;
    position: relative;
    min-width: 200px;

    .gdrive-picker {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      overflow: hidden;
    }

    .m-card-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @media only screen and (max-width: 767px) {
        padding: 10px;
      }

      .card-title {
        margin: 15px 0 10px 0;
        font-size: 17px;
        transition: $transition-base;
        text-align: center;

        @media only screen and (max-width: 767px) {
          margin: 5px 0 10px 0;
          font-size: 16px;
        }
      }

      .m-card-text {
        transition: $transition-base;
      }

      .card-text {
        font-weight: $font-weight-normal;
      }

      .type-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 4rem;
        transition: $transition-base;

        @media only screen and (max-width: 991px) {
          width: 3rem;
          height: 3rem;
        }

        i {
          font-size: 2.5rem;
          width: 2.5rem;
          height: 2.5rem;
          line-height: 2.5rem;

          @media only screen and (max-width: 991px) {
            font-size: 2rem;
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
          }
        }

        svg {
          height: 2.5rem;
          fill: white;
          transition: $transition-base;

          @media only screen and (max-width: 991px) {
            height: 2rem;
          }
        }
      }
    }

    &.bg-primary {
      background: $primary !important;
      border: 2px solid $primary;
      transition: all 0.25s;

      &:hover {
        background: $primary-bg !important;

        .icon {
          color: $primary;
        }

        .m-card-body {
          color: $primary;
        }
      }
    }
  }
}

.modal-dialog-upload-data {
  @media only screen and (min-width: 992px) {
    max-width: 560px;
  }

  @media only screen and (min-width: 992px) {
    max-width: 550px;
  }
  &.modalFive{
    max-width: 1200px;
    width: 90vw;
  }
  &.modalFour{
    max-width: 960px;
    width: 90vw;
  }
  // &.modalTwo{
  //   max-width: 600px;
  //   width: 600px;
  // }
}

@import "./style/font-open-sans";
@import "./style/variables";
@import "./style/confirm-alert";
@import "./style/modal";
@import "./style/status-icon";
@import "./style/toast";
@import "./style/tables";
@import "./style/custom-dropdown";

html,
body,
#root {
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.app,
.app-body,
.main {
  min-height: 100vh;
  height: 100%;
}

.app.ext {
  display: flex;
  flex-direction: column;

  .ext-header {
    background-color: $dark;
    padding: 0.75rem 1.5rem;
    display: flex;
    justify-content: space-between;
  }

  .app-body {
    height: auto;
    min-height: auto;
    flex-grow: 1;

    .main {
      min-height: auto;
      .layout {
        margin-top: 0;
      }
    }
  }
}

.app-body {
  width: 100%;
  min-height: 100%;

  .main {
    z-index: 0;
    transition: padding-left 0.3s;
    position: relative;
    padding-top: 0;
    min-height: 100%;
    width: 100%;
  }
}

//change color of font on whole page
// !!! color of chart's legend change in options of chart !!!
body,
.table,
.form-control,
.form-control:focus {
  color: $body-color;
}

.dropdown > .btn{
  &:not(.dropdown-toggle):not(.btn-link) {
    text-transform: none;
  }
}

.btn {
  // min-height: 40px;
  font-weight: 600;
  letter-spacing: 0.05rem;

  &:not(.dropdown-toggle):not(.btn-link) {
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  i {
    // line-height: 1.5;
    height: 100%;
    display: inline-block;
    line-height: 1.5;
  }

  &.with-icon {
    i {
      margin-right: 0.5rem;
    }
  }

  &.with-right-icon {
    i {
      margin-left: 0.25rem;
    }
  }

  &.btn-icon {
    width: $input-height;
    height: $input-height;
    line-height: $input-height;
    padding: 0;

    > i {
      height: $input-height;
      line-height: $input-height;
    }
  }
}

.quill-editor {
  .ql-toolbar {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background: $secondary-bg;
  }

  .ql-container {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.no-wrap-table {
  td,
  th {
    white-space: nowrap;
  }
}

.page-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.dark {
    background-color: $dark;
  }
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingContent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noResults {
  // background-color: $secondary-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  font-style: italic;
}

.summary-item {
  display: flex;
  padding: 10px 12px;

  .summary-key {
    padding-right: 5px;
    font-weight: bold;
    flex: 0 0 200px;
    border-right: 1px solid #e4e7ea;
  }

  .summary-value {
    padding-left: 15px;
  }

  & + .summary-item {
    border-top: 1px solid #e4e7ea;
  }
}

.app-flex-center {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-full-height {
  height: 100%;
  overflow: hidden;
}

.fullscreen-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
}

.align-flex-start {
  align-items: flex-start;
}

.dropdown-menu {
  z-index: 9999;
}

.code-editor {
  width: 100% !important;
  border-radius: 4px;
}

.virtualized-list {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.react-grid-item.react-grid-placeholder {
  background-color: $primary;
  border-radius: 0.5rem;
}

.search-field {
  min-width: 15rem;
}
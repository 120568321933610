/* open-sans-300 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-300italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Italic'), local('OpenSans-Italic'),
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800.eot'); /* IE9 Compat Modes */
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800.woff') format('woff'), /* Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    src: url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800italic.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800italic.woff') format('woff'), /* Modern Browsers */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/open-sans/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}